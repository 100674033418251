body{
    background: #bdc3c7;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2c3e50, #bdc3c7);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2c3e50, #bdc3c7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.form{
    background-color: white;
    border-radius: 10px;
    /* width: '60%'; */
    /* margin-left: 200px ; */
    margin-top: 10px ;
    margin-bottom: 10px ;
    padding: 10px;
    flex: 1;
    align-items: center;
    align-content: center;

    /* height: 600px; */
}

.form-body{
    text-align: center;
    padding: 10px 10px;
}

.form-body > *{
    padding: 5px;
}

.form__label{
    width: 40%;
}

.form_input{
    width: 100%;
}

.footer{
    text-align: center;
}